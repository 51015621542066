<template>
  <div id="crafting-table" class="d-flex flex-column flex-xl-row m-5 justify-content-center align-items-center">
    <div id="crafting-table-selection" class="d-flex flex-column">
      <ul class="list-unstyled mb-0 d-flex" id="crafting-tabs">
        <li v-for="(cat, index) in categories" :key="index" @click="setCategory(cat)"
            class="p-3 cursor-pointer mt-auto"
            :class="{'active': cat === category || category === null && cat === 'all'}">
          <McIcon :icon="getCat(cat)"/>
        </li>
      </ul>
      <div class="recipe-list">
        <div class="d-flex flex-row flex-wrap overflow-auto">
          <div v-for="(recipe, index) in recipesForCategory" :key="index" class="p-3 cursor-pointer position-relative"
               @click="selectRecipe(recipe)">
            <Item :item="{Item: recipe.outcome}" :size="48"/>
            <span v-if="getSkillLevel < recipe.level" class="position-absolute top-0 right-0">
              <VTooltip>
                <img src="/img/sprites/lock.png"/>
                <template #popper>
                  <span>Requires level {{ recipe.level }} crafting</span>
                </template>
              </VTooltip>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div id="crafting-table-selected" class="d-flex">
      <p v-if="!selectedRecipe" class="p-5 mt-4 text-white">Select a recipe from the other side</p>
      <CraftingRecipeRow :recipe="selectedRecipe" v-else/>
    </div>
  </div>
</template>
<script>
import Item from "@/components/items/Item";
import McIcon from "@/components/McIcon";
import CraftingRecipeRow from "@/components/CraftingRecipeRow";

export default {
  name: 'About',
  components: {CraftingRecipeRow, McIcon, Item},
  data() {
    return {
      category: null,
      selectedRecipe: null,
    }
  },
  computed: {
    skills() {
      return this.$store.state.config.zones && this.$store.state.config.zones.filter(i => i.skill === 'crafting' && i.can_craft)
        .sort((a, b) => {

          if (a.level > b.level)
            return 1;

          if (a.level < b.level)
            return -1;

          return 0;
        });
    },
    categories() {
      const arr = Array.from(new Set(this.skills.map((({category}) => category))));
      arr.unshift('all')
      return arr;
    },
    recipesForCategory() {
      if (!this.category || this.category === 'all')
        return this.skills

      return this.skills.filter(s => s.category === this.category)
    },
    getSkillLevel() {
      return this.$store.getters["user/getLevelForSkill"](
        'crafting'
      );
    },
  },
  methods: {
    setCategory(cat) {
      if (this.category === cat)
        this.category = null;
      else
        this.category = cat;
    },
    getCat(cat) {
      switch (cat) {
      case 'all':
        return 'equip';
      case 'tools':
        return 'diamond-pickaxe';
      case 'armor':
        return 'diamond-chestplate';
      case 'weapons':
        return 'diamond-sword';
      case 'misc':
        return 'silk'
      default:
        return 'wooden-pickaxe';
      }
    },
    selectRecipe(recipe) {
      this.selectedRecipe = recipe;
    },

    stopSkill() {
      this.$socket.emit("auth:stop skill");
    },
    startSkill() {
      this.$socket.emit("auth:start skill", this.selectedRecipe.id);
    },
  }
}
</script>
