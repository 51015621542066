<template>
  <div class="d-flex flex-column recipe-column flex-grow-1 m-5"
       :class="{'disabled': !hasIngredients}">
    <div class="d-flex align-items-center lh-1">
      <h4 class="text-white">{{ recipe.outcome.name }}</h4>
    </div>
    <div class="messages">
      <transition-group name="messageList">
        <div class="message" v-for="m in messages" :key="m.id">
          <div class="d-inline-flex px-4 flex-row align-items-center justify-content-center">
            <template v-if="m.items">
              <Item
                  :item="i"
                  :size="32"
                  v-for="(i, index) in m.items"
                  :animate="false"
                  :key="index"/>
            </template>
            {{ m.message }}
          </div>
        </div>
      </transition-group>
    </div>
    <div class="ingredients d-flex flex-row">
      <Item :item="{Item: ing.item}"
            v-for="(ing, i) in recipe.requires"
            :key="i"
            class="me-2"
            :size="48"
            :quantity="ing.amount"
            :invalid-amount="invalidQuantity(ing)"/>
    </div>
    <div class="d-flex align-items-center mt-auto mb-2 w-100">
      <button @click="startSkill" v-if="!started && !waitingForStatus" :disabled="!hasIngredients"
              class="btn btn-primary w-100">Craft ({{ recipe.xp }}xp)
      </button>
      <button @click="stopSkill" v-if="started || waitingForStatus" class="btn btn-primary w-100">Stop</button>
    </div>
    <SocketProgressBar :skill="recipe"/>
  </div>
</template>
<style lang="scss" scoped>

.message .item {
  background: none !important;
}
</style>
<script>
import SocketProgressBar from "./SocketProgressBar";
import store from '../store'
import Item from "@/components/items/Item";
import {mapGetters} from "vuex";

export default {
  name: 'CraftingRecipeRow',
  components: {Item, SocketProgressBar},
  data() {
    return {
      store: store.state,
      started: false,
      messages: [],
    }
  },
  props: {
    recipe: {
      type: Object,
      required: true,
    }
  },
  methods: {
    startSkill() {
      this.$socket.emit('auth:start skill', this.recipe.id)
    },
    stopSkill() {
      this.$socket.emit('auth:stop skill')
    },

    invalidQuantity(item) {
      if (!item || !item.item)
        return false;

      const reqIngredient = item;
      const inventoryItem = this.inventory.find(s => {
        if (s.item_id === reqIngredient.item.id)


          return s.item_id === reqIngredient.item.id && s.amount >= reqIngredient.amount
      });

      return !inventoryItem;
    },
  },
  computed: {
    ...mapGetters({
      inventory: "user/inventory",
      heat: "user/heat",
    }),
    hasIngredients() {
      let hasEnough = true;

      for (const i in this.recipe.requires) {
        const reqIngredient = this.recipe.requires[i];

        const inventoryItem = this.inventory.find(s => s.item_id === reqIngredient.item.id && s.amount >= (reqIngredient.amount || 1));

        if (!inventoryItem)
          hasEnough = false;
      }

      return hasEnough;
    },
    waitingForStatus() {
      return this.store.user.character.current_skill === this.recipe.id && !this.started;
    },
  },
  sockets: {
    'GAIN_XP': function (skill) {
      if (skill.skill === this.recipe.id && this.messages.length < 5) {
        this.messages.push({
          id: this.messages.length + Math.floor(Math.random() * 100),
          message: `+${skill.xp}xp`,
          items: skill.items
        });

        setTimeout(() => {
          this.messages.splice(0, 1);
        }, 3000);
      }
    },

    'STOP_SKILL': function () {
      this.started = false;
    },
    'START_SKILL': function (skill) {
      if (skill.id === this.recipe.id) {
        this.timer = skill.timer;
        this.started = true;
      }
    },
  }
}
</script>
